<template>
  <div>
    <div class="table-page-search-wrapper" style="margin-left: 200px">
      <a-spin :spinning="loading">
        <a-form :form="form" >
          <a-row :gutter="[16,16]">
            <a-col :span="8">
              <a-form-item label="Id" v-show="false">
                <a-textarea v-decorator="['id', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
              </a-form-item>
              <a-form-item label="明细名称">
                <a-input
                  disabled
                  v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]"
                  placeholder="请输入"
                />
              </a-form-item>
              <a-form-item label="产权面积">
                <a-input-number
                  disabled
                  v-decorator="['property_area', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                  placeholder="请输入"
                />
              </a-form-item>
              <a-form-item label="真实面积">
                <a-input-number
                  disabled
                  v-decorator="['real_area', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                  placeholder="请输入"
                />
              </a-form-item>
              <a-form-item label="已使用面积">
                <a-input-number
                  disabled
                  v-decorator="['used_area', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                  placeholder="请输入"
                />
              </a-form-item>
              <a-form-item label="可用面积">
                <a-input-number
                  disabled
                  v-decorator="['available_area', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                  placeholder="请输入"
                />
              </a-form-item>
              <a-form-item label="是否计租">
                <a-switch
                  v-decorator="['is_count', {initialValue: false, valuePropName: 'checked'}]"
                  placeholder="请选择"
                />
              </a-form-item>
              <a-form-item label="备注">
                <a-textarea :autoSize="{minRows:2,maxRows:6}" v-decorator="['remark', {initialValue: '',rules: [{required: false, message: '必填项，请填写信息'}, {max:256, message: '输入超出长度限制'}]}]" />
              </a-form-item>
              <a-form-item label="上传" v-show="false">
                <a-textarea v-decorator="['imgs', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
              </a-form-item>
              <a-form-item label="图片上传" v-show="fileList.length !== 0">
                <div class="clearfix">
                  <a-upload
                    disabled
                    name="file"
                    accept="image/png, image/jpeg, image/jpg"
                    :action="actionUrl"
                    :data="new_multipart_params"
                    list-type="picture-card"
                    :file-list="fileList"
                    :before-upload="beforeUpload"
                    @preview="handlePreview"
                    @change="handleChange"
                  >
                    <div v-if="false">
                      <a-icon type="plus" />
                      <div class="ant-upload-text">
                        上传
                      </div>
                    </div>
                  </a-upload>
                  <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                    <img alt="example" style="width: 100%" :src="previewImage" />
                  </a-modal>
                </div>
              </a-form-item>
              <a-form-item
                v-bind="buttonCol">
                <a-row>
                  <a-col span="6">
                    <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
                  </a-col>
                  <a-col span="10" style="margin-left: 20px">
                    <a-button @click="handleGoBack">返回</a-button>
                  </a-col>
                  <a-col span="8"></a-col>
                </a-row>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { zone_property_cell_img_delete, zone_property_cell_update } from '@/api/zone_property_cell'
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      loading: false,
      form: this.$form.createForm(this),
      id: 0,
      actionUrl: '',
      previewVisible: false,
      previewImage: '',
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      fileList: [],
      filePathList: [],
      filePathUrls: ''
    }
  },
  // beforeCreate () {
  //   this.form = this.$form.createForm(this)
  // },
  mounted () {
    this.$nextTick(() => {
      this.loadEditInfo(this.record)
    })
  },
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    },
    beforeUpload (file) {
      const name = file.name
      const suffix = name.substring(name.lastIndexOf('.') + 1)
      this.new_multipart_params.key = file.uid + '.' + suffix
      this.form.setFieldsValue({ entrance_img: this.new_multipart_params.key })
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt5M = file.size / 1024 / 1024 < 5
      return isJpgOrPng && isLt5M
    },
    // 进口图片
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange ({ fileList, file }) {
      this.fileList = fileList
      if (file.status === 'done') {
        this.filePathList.push(this.actionUrl + '/' + this.new_multipart_params.key)
        this.form.setFieldsValue({ imgs: this.filePathList.toString() })
      } else if (file.status === 'removed') {
        if (this.filePathUrls.search(file.url) >= 0) {
          zone_property_cell_img_delete(file.id).then(res => {
            console.log(res)
            this.model.imgs.slice(this.filePathUrls.search(file.url), 1)
          })
        }
        this.filePathList = this.filePathList.filter(function (item) {
          return !(item.search(file.name) > 0)
        })
        this.form.setFieldsValue({ imgs: this.filePathList.toString() })
      }
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          this.loading = true
          values.imgs = undefined
          values.img = undefined
          values.name = undefined
          values.property_area = undefined
          values.real_area = undefined
          values.used_area = undefined
          values.available_area = undefined
          zone_property_cell_update(values, this.id)
            .then((res) => {
              this.handleGoBack()
            }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    handleCancel (file) {
      this.previewVisible = false
    },
    handleGetInfo () {

    },
    loadEditInfo (data) {
      const { form } = this
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        if (data.imgs !== undefined && data.imgs.length > 0) {
          this.fileList = data.imgs.map((item, index) => {
            item.url = item.img
            item.uid = index
            item.status = 'done'
            item.name = item.img.substring(item.img.lastIndexOf('/') + 1)
            this.filePathUrls = this.filePathUrls + ',' + item.img
            return item
          })
          data.imgs = this.filePathUrls
        } else {
          data.imgs = ''
        }
        console.log(data)
        const formData = pick(data, ['id', 'name', 'property_area', 'real_area', 'used_area', 'available_area', 'is_count', 'remark', 'imgs'])
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
