<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="handelsubmit"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="明细名称">
              <a-input
                v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item label="产权面积(平方米)">
              <a-input-number
                style="width: 200px"
                v-decorator="['property_area', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item label="真实面积(平方米)">
              <a-input-number
                style="width: 200px"
                v-decorator="['real_area', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item label="是否计租">
              <a-switch
                v-decorator="['is_count', {initialValue: false, valuePropName: 'checked'}]"
                placeholder="请选择"
              />
            </a-form-item>
            <a-form-item label="备注">
              <a-textarea :autoSize="{minRows:2,maxRows:6}" v-decorator="['remark', {initialValue: '',rules: [{required: false, message: '必填项，请填写信息'}, {max:256, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="上传" v-show="false">
              <a-textarea v-decorator="['imgs', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="图片上传">
              <div class="clearfix">
                <a-upload
                  v-decorator="[
                    'img',
                    {
                      valuePropName: 'img'
                    },
                  ]"
                  name="file"
                  accept="image/png, image/jpeg, image/jpg"
                  :action="actionUrl"
                  :data="new_multipart_params"
                  list-type="picture-card"
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                  @preview="handlePreview"
                  @change="handleChange"
                >
                  <div v-if="fileList.length < 1000">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      上传
                    </div>
                  </div>
                </a-upload>
                <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                  <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'
import { oss_token_list } from '@/api/oss_token'
// 表单字段
const fields = ['imgs']

function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      form: this.$form.createForm(this),
      actionUrl: '',
      previewVisible: false,
      previewImage: '',
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      fileList: [],
      filePathList: []
    }
  },
  created () {
    oss_token_list().then(({ data }) => {
      console.log(data, '我是返回值')
      this.new_multipart_params.OSSAccessKeyId = data.accessid
      this.new_multipart_params.policy = data.policy
      this.new_multipart_params.signature = data.signature
      this.actionUrl = 'https://static.jnzbtz.com'
    })
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  mounted () {
  },
  methods: {
    handelsubmit () {
      this.$emit('ok')
    },
    beforeUpload (file) {
      const name = file.name
      const suffix = name.substring(name.lastIndexOf('.') + 1)
      this.new_multipart_params.key = file.uid + '.' + suffix
      this.form.setFieldsValue({ entrance_img: this.new_multipart_params.key })
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt5M = file.size / 1024 / 1024 < 5
      return isJpgOrPng && isLt5M
    },
    // 进口图片
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange ({ fileList, file }) {
      this.fileList = fileList
      if (file.status === 'removed') {
        this.form.setFieldsValue({ imgs: '' })
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        this.filePathList.push(this.actionUrl + '/' + this.new_multipart_params.key)
        this.form.setFieldsValue({ imgs: this.filePathList.toString() })
      } else if (file.status === 'removed') {
        this.filePathList = this.filePathList.filter(function (item) {
          return !(item.search(file.name) > 0)
        })
        this.form.setFieldsValue({ imgs: this.filePathList.toString() })
      }
    },
    handleCancel (file) {
      this.previewVisible = false
      console.log(file, '我是删除')
    }
  }
}
</script>
