<template>
  <div>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
      title="资产明细"
      sub-title=""
      @back="() => $router.back()"
    >
      <a-row type="flex">
        <a-statistic title="" :value="'名称：' + this.name" />
      </a-row>
    </a-page-header>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="名称">
              <a-input v-model="queryParam.name" placeholder="请输入" allowClear/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 100px">
        {{ text.toFixed(2) }}
      </div>
      <span slot="imgs" slot-scope="image_urls">
        <a>
          <a-avatar
            v-if="image_urls.length > 0"
            slot="image_urls"
            :src="image_urls[0].img"
            shape="square"
            @click="showAllImages(image_urls)"
          />
        </a>
        <a v-if="image_urls.length > 1" @click="showAllImages(image_urls)">...</a>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a @click="editImages(record)" >编辑图片</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a @click="corporationList(record)" >企业</a>
        </template>
      </span>
    </s-table>
    <a-drawer
      title="企业列表"
      placement="right"
      :closable="false"
      :visible="visibleDrawer"
      @close="onClose"
      width="700"
    >
      <a-table
        :columns="drawer_columns"
        :data-source="corporations"
        :pagination="false"
      >
        <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}
        </span>
      </a-table>
    </a-drawer>
    <create-form
      v-if="visible"
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <edit-images-form
      v-if="images_visible"
      ref="createImagesModal"
      :visible="images_visible"
      :loading="confirmImagesLoading"
      :model="mdl_imgs"
      @cancel="handleImagesCancel"
      @ok="handleImagesOk"
    />
    <preview
      ref="preview"
      :visible="previsible"
      :loading="preLoading"
      :content="content"
      @cancel="handlepreCancel"
      @ok="handlepreOk"
    />

    <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
      <img alt="example" style="width: 300px; margin: 0 auto" :src="previewurl" />
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import {
  zone_property_cell_list,
  zone_property_cell_create,
  zone_property_cell_img_update, zone_property_cell_delete
} from '@/api/zone_property_cell'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import EditImagesForm from '../modules/EditImagesForm'
import Preview from '../modules/preview'

export default {
  name: 'TableList',
  components: {
    EditImagesForm,
    STable,
    CreateForm,
    Preview
  },
  data () {
    return {
      property_bill_visible: false,
      rent_bill_visible: false,
      previewvisible: false,
      previewurl: '',
      name: '',
      corporations: [],
      corporation_id: null,
      tip: '',
      corList: [],
      visibleDrawer: false,
      bookLoading: false,
      curzone_property_id: '',
      billvisble: false,
      billLoading: false,
      billTime: {},
      content: '',
      previsible: false,
      loading: false,
      confirmLoading: false,
      confirmPropertyBillLoading: false,
      confirmRentBillLoading: false,
      confirmAttachmentLoading: false,
      preLoading: false,
      dictionaries: Dictionaries,
      visibleAttachment: false,
      attachmentListItemId: 0,
      // 创建窗口控制
      images_visible: false,
      confirmImagesLoading: false,
      visible: false,
      mdl: {},
      mdl_imgs: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      drawer_columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' },
          align: 'center',
          width: 80
        },
        {
          title: '名称',
          dataIndex: 'corporation_name',
          ellipsis: true
        },
        {
          title: '开始时间',
          dataIndex: 'start_date',
          ellipsis: true,
          align: 'center',
          width: 120
        },
        {
          title: '结束时间',
          dataIndex: 'end_date',
          ellipsis: true,
          align: 'center',
          width: 120
        }
      ],
      // 表头
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '产权面积',
          dataIndex: 'property_area',
          scopedSlots: { customRender: 'count_render' },
          align: 'center'
        },
        {
          title: '真实面积',
          dataIndex: 'real_area',
          scopedSlots: { customRender: 'count_render' },
          align: 'center'
        },
        {
          title: '已使用面积',
          dataIndex: 'used_area',
          scopedSlots: { customRender: 'count_render' },
          align: 'center'
        },
        {
          title: '可使用面积',
          dataIndex: 'available_area',
          scopedSlots: { customRender: 'count_render' },
          align: 'center'
        },
        {
          title: '是否计租',
          dataIndex: 'is_count',
          customRender: (text) => text ? '是' : '否',
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true
        },
        {
          width: 80,
          title: '图片',
          dataIndex: 'imgs',
          align: 'center',
          scopedSlots: { customRender: 'imgs' }
        },
        {
          width: 250,
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return zone_property_cell_list(Object.assign(parameter, this.queryParam, { zone_property_id: this.$route.params.id }))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      }
    }
  },
  created () {
    this.name = this.$route.query.record
  },
  methods: {
    showDrawer () {
      this.visibleDrawer = true
    },
    onClose () {
      this.visibleDrawer = false
    },
    corporationList (record) {
      this.corporations = record.corporation_info
      this.showDrawer()
    },
    getFileSuffix (filename) {
      console.log(filename, '787800')
      return filename.substring(filename.lastIndexOf('.') + 1).toLowerCase()
    },
    getFileName (filename) {
      let name = filename.substring(filename.lastIndexOf('/') + 1)
      const suffix = filename.substring(filename.lastIndexOf('.')).toLowerCase()
      console.log(name, name.length)
      name = name.length - suffix.length > 10 ? name.substring(0, 5) + '...' + name.substring(8, 10) + suffix : name
      return name
    },
    getFileFullName (filename) {
      return filename.substring(filename.lastIndexOf('/') + 1)
    },
    openPreview (img_url) {
      const fileType = ['pdf', 'jpg', 'jpeg', 'png']
      const suffix = img_url.substring(img_url.lastIndexOf('.') + 1).toLowerCase()
      if (fileType.indexOf(suffix) > -1) {
        this.previewvisible = true
        this.previewurl = img_url
      } else {
        this.$message.error('附件错误，不支持预览')
      }
    },
    onChange (value) {
      console.log(value)
    },
    onSearch (searchText) {
    },
    onSelect (value) {
      this.queryParam.corporation_id = this.corList.filter((item) => (item.name === value) && item)[0].id

      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    },
    editImages (record) {
      this.mdl_imgs = record
      this.images_visible = true
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handlepreCancel () {
      this.previsible = false
    },
    handlepreOk () {
      this.previsible = false
    },
    showAllImages (urls) {
      this.previsible = true
      this.content = urls.map(url => {
        return url.img
      }).toString()
    },
    handleImagesCancel () {
      this.images_visible = false
      const form = this.$refs.createImagesModal.form
      form.resetFields() // 清理表单数据（可不做）
      this.$refs.table.refresh()
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleDelete (record) {
      zone_property_cell_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')

        this.$refs.table.refresh()
      })
    },
    handleImagesOk () {
      const form = this.$refs.createImagesModal.form
      this.confirmImagesLoading = true
      form.validateFields((errors, values) => {
        if (values.imgs === undefined || values.imgs.length === 0) {
          this.confirmImagesLoading = false
          this.images_visible = false
          // 重置表单数据
          form.resetFields()
          // 刷新表格
          this.$refs.table.refresh()
          return
        }
        if (!errors) {
          values.imgs = values.imgs.split(',')
          values.img = undefined
          values.zone_property_cell_id = this.mdl_imgs.id
          zone_property_cell_img_update(values).then(res => {
            this.images_visible = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          }).finally(() => {
            this.confirmImagesLoading = false
          })
        } else {
          this.confirmImagesLoading = false
        }
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          if (values.imgs !== undefined && values.imgs.length > 0) {
            values.imgs = values.imgs.split(',')
          }
          values.img = undefined
          values.zone_property_id = parseInt(this.$route.params.id, 10)
          zone_property_cell_create(values).then(res => {
            this.visible = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
            console.log('创建成功------')
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
