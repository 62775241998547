import request from '@/utils/request'

const zone_property_cellApi = {
  zone_property_cell_list: '/zone_property_cell/',
  zone_property_cell_create: '/zone_property_cell/',
  zone_property_cell_update: '/zone_property_cell/',
  zone_property_cell_delete: '/zone_property_cell/',
  zone_property_cell_img_update: '/zone_property_cell/img/',
  zone_property_cell_img_delete: '/zone_property_cell/img/'
}

/**
 * 列表
 */
export function zone_property_cell_list (parameter) {
  return request({
    url: zone_property_cellApi.zone_property_cell_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function zone_property_cell_create (parameter) {
  return request({
    url: zone_property_cellApi.zone_property_cell_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function zone_property_cell_update (parameter, zone_property_cell_id) {
  return request({
    url: zone_property_cellApi.zone_property_cell_update + zone_property_cell_id + '/',
    method: 'patch',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 修改
 * @param
 * {
 *  "change_to": 0
 * }
 */
export function zone_property_cell_img_update (parameter) {
  return request({
    url: zone_property_cellApi.zone_property_cell_img_update,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function zone_property_cell_img_delete (zone_property_cell_id) {
  return request({
    url: zone_property_cellApi.zone_property_cell_img_delete + zone_property_cell_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function zone_property_cell_delete (zone_property_cell_id) {
  return request({
    url: zone_property_cellApi.zone_property_cell_delete + zone_property_cell_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
